import React, { useEffect } from 'react';

export default function ImagePopup({ onClose, isOpen, card, cards, setSelectedCard,


}) {
  useEffect(() => {
    function handleKeyDown(event) {
      if (!isOpen) return;

      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrevious();
      } else if (event.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, card]);

  function handleNext() {
    const currentIndex = cards.findIndex((c) => c._id === card._id);
    const nextIndex = (currentIndex + 1) % cards.length;
    setSelectedCard(cards[nextIndex]);
  }

  function handlePrevious() {
    const currentIndex = cards.findIndex((c) => c._id === card._id);
    const prevIndex = (currentIndex - 1 + cards.length) % cards.length;
    setSelectedCard(cards[prevIndex]);
  }

  return (
    <div className={`popup popup_type_image ${isOpen ? "popup_opened" : ""}`}>
      <div className="popup__image-container">
        <button type="button" className="popup__close-btn" onClick={onClose} />

        <img src={card.link} alt={card.name} className="popup__image" />
        

        <p className="popup__caption">{card.name}</p>
         <button className="popup__nav-button popup__nav-button--prev" onClick={handlePrevious}>
          <svg className="popup__nav-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15 19l-7-7 7-7" fill='transparent' stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      <button className="popup__nav-button popup__nav-button--next" onClick={handleNext}>
        <svg className="popup__nav-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M9 5l7 7-7 7" fill='transparent' stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      </div>
     
    </div>
  );
}
